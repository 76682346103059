<template>
    <div>
        <iexplorer
            :struct="struct"
            :structHeader="true"
            :items="items"
            :orderUp="increaseModSortOrder"
            :orderDown="decreaseModSortOrder"
            :itemClick="toggleItem"
            type="modGroups"
            reciever="RECIEVE_MODS" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import bus from '../store/action_bus.js'
import iexplorer from '../components/explorer/iexplorer.vue'

export default {
    name: 'StoreModOverview',
    computed: {
        items() {
            return this.mods.map(el => {
                const copy = Object.assign({}, el)

                return Object.assign(copy, {
                    modGroup: this.modGroups.find(group => (group.id === el.modGroupId)).title,
                })
            })
        },
        ...mapState({
            editableItems: state => state.editableItems,
            editingModalEnabled: state => state.editingModalEnabled,
            modGroups: state => state.modGroups,
            mods: state => state.mods,
        }),
    },
    mounted() {
        this.$store.commit('updatePageTitle', 'Mods')
    },
    data() {
        return {
            struct: {
                title: {
                    value: item => item.title,
                },
                modGroup: 'Group',
            },
        }
    },
    methods: {
        toggleItem(item) {
            const { id } = item

            if (bus.editModeActive) {
                this.toggleEditableItem({id, type: 'mods'})
            } else {
                this.$router.push('/store/test-store/mod/mods/' + id)
            }
        },
        increaseModSortOrder: () => true,
        decreaseModSortOrder: () => true,
        ...mapActions([
            'toggleEditableItem',
        ]),
    },
    components: {
        iexplorer,
    },
}
</script>

<style lang="scss">
</style>
